import { Link, PageProps, graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Card, CardMedia, Typography, makeStyles } from "@material-ui/core";
import { GatsbyImage } from "gatsby-plugin-image";

const useStyles = makeStyles(theme => ({
  hero: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  article: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  link: {
    textDecoration: 'none',
  },
}));

const CategoryTemplate: React.FC<PageProps<GatsbyTypes.PostsByCategoryQuery>> = ({ data, pageContext }) => {
  const classes = useStyles();
  const { category } = pageContext as any;

  return (
    <Layout>
      <SEO />

      <Card className={classes.hero}>
        <CardMedia>
          <GatsbyImage image={data.hero?.childImageSharp?.gatsbyImageData!} alt=""/>
        </CardMedia>
      </Card>

      <Typography variant="h5">{category} の記事一覧</Typography>

      {data.posts.edges.map(({ node }) => (
        <article className={classes.article} key={node.id}>
          <Typography variant="subtitle1">
            {node.frontmatter?.date}
          </Typography>
          <Link to={node.frontmatter?.path!} className={classes.link}>
            <Typography variant="h5" gutterBottom>
              {node.frontmatter?.title}
            </Typography>
          </Link>
        </article>
      ))}

    </Layout>
  );
};

export default CategoryTemplate;

export const pageQuery = graphql`
  query PostsByCategory($category: String!) {
    site {
      siteMetadata {
        title
      }
    }
    hero: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "hero.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    posts: allMarkdownRemark(
      filter: {
        frontmatter: {
          category: { in: [$category] },
          status: { eq: "published" }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 100)
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD")
            path
          }
        }
      }
    }
  }
`;
